import React from 'react'

const Tags = ({batch}) => {
  return (
    <div className='bg-sky-100 px-3 py-0.5 text-[0.7rem] text-blue-600 rounded-[4px]'>
        {batch}
    </div>
  )
}

export default Tags