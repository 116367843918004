import React from 'react'

const Loader = () => {
  return (
    <>
        <section className="dots-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        </section>

    </>
  )
}

export default Loader