import React, { useEffect, useState } from "react";
import JobCard from "../components/JobCard";
import axios from "axios";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from '../components/Loader'
import ReactGA from 'react-ga4';

function Jobs() {
  
  //temporary search until api route is made
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("q");

  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  //tempory filtering logic
  const jobMatchesQuery = (job, query) => {
    const { companyName, jobTitle, location, jobType } = job;
    const normalizedQuery = query.toLowerCase();

    return (
      companyName.toLowerCase().includes(normalizedQuery) ||
      jobTitle.toLowerCase().includes(normalizedQuery) ||
      location.toLowerCase().includes(normalizedQuery) ||
      jobType.toLowerCase().includes(normalizedQuery)
    );
  };
   
  
  const fetchData = async (currentpage) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/getjobs?page=${currentpage}&limit=18`
      );

      let { jobs: currentJobs, totalPages} = res?.data;
      //filtering tempory code for search
        // let filteredJobs = currentJobs;
        // if (searchQuery) {
        //   filteredJobs = filteredJobs.filter((job) =>
        //     jobMatchesQuery(job, searchQuery)
        //   );
        // }
        // if(filteredJobs.length!==0){
        //   currentJobs=filteredJobs;
        // }else{
        //   currentJobs=[];
        //   // toast("Oops! No Job found for your search",{icon: '👎🏼'});
        // } 
        /////////////

        if(currentpage===1){
          setJobs(currentJobs);
        } 
        else setJobs((prevJobs) => [...prevJobs, ...currentJobs]);   
        if(currentpage>=totalPages) setHasMore(false);
     
    } catch (e) {
      if(e.response && e.response.data){
        toast.error(e.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  
  return (
    <div className="mt-24 font-out">
      {jobs.length === 0 ? (
        <div className="flex items-center justify-center py-72 sm:py-[280px]">
           <Loader/>
        </div>
      ) : (
        <div>
          <div className="flex justify-center w-full">
            <h1 className="bg-blue-500 text-white p-1 rounded-md text-xl text-center mb-4 sm:text-[1rem] w-50 animate__animated animate__zoomIn">
              Current Job Listings
            </h1>
          </div>
          <InfiniteScroll
            dataLength={jobs.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<p className="text-center my-10"><Loader/></p>}
            endMessage={<div className="flex justify-center w-full">
                <h1 className="bg-slate-700 text-white p-2 rounded-md text-center my-10 text-sm">😩 Oops no more jobs available!!</h1>
              </div>}
          >
          
            <div className="flex flex-wrap justify-center gap-4 p-2">
              {jobs.map((job) => (
                <JobCard key={job._id} jobs={job} />
              ))}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}

export default Jobs;
