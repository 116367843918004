function timeSince(date) {

    var seconds = Math.floor((new Date() - new Date(date)) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " year";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " month";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " day";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hour";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " min";
    }
    return Math.floor(seconds) + " sec";
  }
export default timeSince;