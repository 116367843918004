import React from 'react';
import 'animate.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<>
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH}>
      <Toaster position="top-right"/> 
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
  </>
);

