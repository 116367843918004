const sortByEaryFirst = (batch) => {
  batch.sort((a, b) => {
    const numA = parseInt(a);
    const numB = parseInt(b);

    if (!isNaN(numA) && !isNaN(numB)) return numA - numB;

    if (!isNaN(numA)) return -1;
    if (!isNaN(numB)) return 1;

    return a.localeCompare(b);
  });
  return batch;
};

export default sortByEaryFirst;
