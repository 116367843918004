import React from "react";
import Icons from "../utils/icon";
import { generateSlug } from "../utils/helper/banner";
import {Link } from 'react-router-dom';

const SavedJobs = ({jobs,onRemove}) => {

  const slug = generateSlug(jobs.jobTitle); 

  const handleRemove=()=>{
    onRemove(jobs.jobID);
  }

  return (
    <>
      <div className="bg-white bg-opacity-50 shadow-md p-2 rounded-md z-1 flex justify-between items-center md:flex-col gap-2">
        <div className="flex gap-2 items-center md:self-start">
          <img src={jobs.image} alt="company-logo" className="w-12 h-12 md:w-16 md:h-16 rounded-md border-4 border-slate-300"/>
          <div className="flex flex-col hidden md:block">
             <p className="mb-0 text-sm">{jobs.companyName}</p>
             <h1 className="mb-0 text-[0.9rem]">{jobs.jobTitle}</h1>
             <p className="mb-0 text-sm">{jobs.jobType}</p>
          </div>
          <div className="flex gap-2 md:hidden">
            <p className="mb-0">{jobs.companyName} |</p>
            <p className="mb-0">{jobs.jobTitle} |</p>
            <p className="mb-0">{jobs.jobType}</p>
          </div>
        </div>
        <div className="flex gap-2 md:w-full">
          <Link to={{ pathname: `/jobdetails/${jobs.jobID}/${slug}`}} className="no-underline p-2 h-10 rounded-md text-[0.9rem] bg-blue-500 text-white flex items-center md:w-full sm:h-8 font-semibold hover:bg-blue-600">Details</Link>
          <Link to={{ pathname: `/discuss/${jobs.jobID}/${slug}`}} className="no-underline p-2 h-10 rounded-md text-[0.9rem] bg-blue-500 text-white flex items-center gap-2 md:w-full sm:h-8 font-semibold hover:bg-blue-600">{Icons.discuss}Discuss</Link>
          <Link to="" className="no-underline p-2 h-10 rounded-md text-[0.9rem] bg-red-500 text-white flex items-center gap-2 md:w-full sm:h-8 font-semibold hover:bg-red-600" onClick={handleRemove}>{Icons.cross}Remove</Link>
        </div>
      </div>
    </>
  );
};

export default SavedJobs;
