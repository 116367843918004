import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState,useEffect } from "react";
import JobHubHome from "./Pages/Home";
import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Jobs from "./Pages/Jobs";
import Notfound from "./Pages/Notfound";
import Discussion from "./Pages/JobDiscussion";
import JobDescription from "./Pages/JobDescription";
import Footer from "./components/Footer";
import Scrolltotop from "./components/Scrolltotop";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import PostJobForm from "./Pages/PostJobForm";
import Profile from "./Pages/Profile";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { GoogleOneTapAuth } from "./components/Auth";
import ReactGA from 'react-ga4';
ReactGA.initialize('G-PT055H64Y3');
 

function App() {
  
  window.addEventListener("scroll", function () {
    const scrollUp = document.getElementById("scrollup");
    const navbar = document.getElementById("nav-header");
    if (this.scrollY >= 100) navbar.classList.add("scroll-header");
    else navbar.classList.remove("scroll-header");
    if (this.scrollY >= 360) scrollUp.classList.add("show-scroll");
    else scrollUp.classList.remove("show-scroll");
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cookies,setCookie, removeCookie] = useCookies(["_user"]);
 
  useEffect(()=>{
    const checkCookie=()=>{
       const userData = cookies['_user'];
       if (userData !== undefined){
         setIsLoggedIn(true);
       }
    }
    checkCookie();
 },[cookies]);

  const handleLogout = () => {
    removeCookie("_user");
    toast.success("Logged out succesfully!");
    setIsLoggedIn(false);
  };

  const [userTimeZone, setUserTimeZone] = useState('');

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimeZone(timeZone);
    console.log('User Time Zone:', timeZone);
  }, []);

  return (
      <BrowserRouter>
        <Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
        {!isLoggedIn && <GoogleOneTapAuth setIsLoggedIn={setIsLoggedIn} />}
        <Routes>
          <Route path="/" exact element={<JobHubHome />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/jobdetails/:jobId/:slug" element={<JobDescription isLoggedIn={isLoggedIn}/>} />
          <Route path="/discuss/:jobId/:slug" element={<Discussion isLoggedIn={isLoggedIn}/>} />
          <Route path="/login" element={<Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path="/register" element={<Register isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path="/postjob" element={<PostJobForm isLoggedIn={isLoggedIn}/>} />
          <Route path="/user/:username" element={<Profile isLoggedIn={isLoggedIn}/>} />
          <Route path="*" element={<Notfound />} />
        </Routes>
        <Scrolltotop />
        <Footer />
      </BrowserRouter>
  );
}

export default App;
