import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import NotLoggedIn from "./NotLoggedIn";
import log from "../utils/images/log.gif";

const PostJobForm = ({ isLoggedIn }) => {
  const [cookies] = useCookies(["_user"]);
  const initialFormData = {
    jobTitle: "",
    companyName: "",
    location: "",
    jobType: "Intern",
    duration: "Permanent",
    salary: "",
    datePosted: new Date().toISOString(),
    image: "",
    jobLink: "",
    description: "",
    tags: {
      batch: [],
    },
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "batch") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        tags: {
          ...prevFormData.tags,
          batch: value.split(",").map((item) => item.trim()),
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userCookie = cookies["_user"];
    if (userCookie.isAdmin === false)
      toast.error("Oops you are not an Admin! - Only admins can post a Job!");
    else {
      const bearerToken = cookies._user.accessToken;
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/jobs/addJob`, formData, {
          headers: {
            authorization: `Bearer ${bearerToken}`,
          },
        })
        .then((res) => {
          toast.success("Job Posted Successfully 💌");
          navigate("/jobs");
        })
        .catch((err) => {
          toast.error("Some Error Occured While Posting Job 😕");
        });
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <div className="font-out flex justify-center items-center mt-24 px-3 mb-20">
         
          <div className="flex gap-4 bg-gray-800 p-4 rounded-2xl shadow-md">

            <div className="flex flex-col gap-3 py-2">
            <h2 className="font-bold text-4xl text-blue-500 sm:text-2xl mb-3">
              {" "}
              Share Job Opportunities: Post Jobs Here
            </h2>

            <div className="flex w-full gap-3 md:flex-col">
              <div className="flex flex-col gap-3 w-full">
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm"
                  placeholder="Company Name"
                  required
                />
                <input
                  type="text"
                  id="jobTitle"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm"
                  placeholder="Job Title"
                  required
                />
                <select
                  id="jobType"
                  name="jobType"
                  value={formData.jobType}
                  onChange={handleChange}
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm bg-white text-gray-400"
                >
                  <option value="intern">Intern</option>
                  <option value="full-time">Full-time</option>
                  <option value="mentorship">Mentorship</option>
                  <option value="hackathon">Hackathon</option>
                  <option value="part-time">Part-time</option>
                </select>
                <input
                  type="text"
                  id="salary"
                  name="salary"
                  value={formData.salary}
                  onChange={handleChange}
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm"
                  placeholder="Salary"
                  required
                />
              </div>

              <div className="flex flex-col gap-3 w-full">
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm"
                  placeholder="Location"
                  required
                />
                <input
                  type="text"
                  id="batch"
                  name="batch"
                  value={formData.tags.batch.join(",")}
                  onChange={handleChange}
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm"
                  placeholder="Batch (Add values seperated by comma)"
                  required
                />
                <input
                  type="url"
                  id="jobLink"
                  name="jobLink"
                  value={formData.jobLink}
                  onChange={handleChange}
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm"
                  placeholder="Job Link"
                  required
                />
                <input
                  type="url"
                  name="image"
                  value={formData.image}
                  onChange={handleChange}
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm"
                  placeholder="Image URL"
                  required
                />
              </div>
            </div>

            <textarea
              id="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="p-2 rounded-lg outline-0 border text-md sm:text-sm"
              placeholder="Job Description"
              required
            />

            <button
              type="submit"
              className="bg-blue-500 rounded-lg text-white py-2 hover:scale-[1.02] hover:bg-blue-600 duration-300 sm:text-sm sm:hover:scale-100"
              onClick={handleSubmit}
            >
              Submit
            </button>
            </div>
            <img src={log} alt="postjob-img" className="rounded-2xl h-[450px] lg:hidden"/>
          </div>
         
        </div>
      ) : (
        <NotLoggedIn />
      )}
    </>
  );
};

export default PostJobForm;
