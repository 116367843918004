import React, { useEffect, useState } from "react";
import NotLoggedIn from './NotLoggedIn'
import Notfound from './Notfound'
import { useParams} from 'react-router-dom';
import {bannerImageArray} from '../utils/helper/banner'
import EditProfileModal from "../components/modals/EditProfileModal";
import { useCookies } from 'react-cookie';
import SavedJobs from "../components/SavedJobs";
import toast from "react-hot-toast";
import ReactGA from 'react-ga4';

const Profile = ({isLoggedIn}) => {
  const [cookies] = useCookies(["_user"]);
  const userData = cookies['_user'];
  const { username } = useParams();
  const [randomBanner, setRandomBanner] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [savedJobs, setsavedJobs] = useState([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const fetchSavedJobs = () => {
      const existingJobs = JSON.parse(localStorage.getItem('savedJobs')) || [];
      setsavedJobs(existingJobs);
    };
    const randomImage = bannerImageArray[Math.floor(Math.random() * bannerImageArray.length)];
    setRandomBanner(randomImage);
    fetchSavedJobs();
  }, []); 

  const removeSavedJob = (ID) => {
    const updatedJobs = savedJobs.filter(job => job.jobID !== ID);
    setsavedJobs(updatedJobs);
    localStorage.setItem('savedJobs', JSON.stringify(updatedJobs));
    toast.success("Job removed")
  };

  return (
    <>
      {isLoggedIn ? (
        username === userData.username ? ( 
          <div className="font-out flex flex-col justify-center items-center mt-24 animate__animated animate__zoomIn">
           
            <div className='flex flex-col gap-4 w-full px-16 mb-10 md:px-2'>
               {/* User Profile Section */}
               <div className="border-1 rounded-md pb-2">
                 <div className=" relative h-40 rounded-t-md sm:h-32" style={{backgroundImage: `url("${randomBanner}")`,backgroundSize: "cover",backgroundRepeat: "no-repeat",}}>
                   <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-md"></div>
                   <img src={userData.picture} alt="user-img" className="relative top-28 left-10 w-28 h-28 rounded-full border-4 border-slate-300 sm:top-[95px] sm:left-6 sm:w-[90px] sm:h-[90px]" />
                   <div className="relative w-96 flex flex-col top-[50px] left-[160px] sm:top-[40px] sm:left-[120px] sm:w-56">
                        <span className="text-2xl mb-0 sm:text-lg font-semibold">Hello {userData.name.split(' ')[0]}</span>
                        <span className="mb-0 text-gray-400 font-light sm:text-sm">@{userData.username}</span>
                   </div>
              
                 </div>
                 <div className="mt-20 flex flex-col gap-2">
                    <div className='bg-gray-200 p-2 rounded-md mx-2'><span className="font-semibold">Email:</span> {userData.email}</div>
                    <div className='bg-gray-200 p-2 rounded-md mx-2'><span className="font-semibold">Username:</span> {userData.username}</div>
                    <div className='bg-gray-200 p-2 rounded-md mx-2'><span className="font-semibold">Name:</span> {userData.name}</div>
                    <button className="mx-2 py-2 bg-blue-500 rounded-md text-white font-semibold w-32 self-end sm:text-sm sm:self-start hover:bg-blue-600" onClick={() => setIsModalOpen(true)}>Edit</button>
                 </div>
               </div>
              {/* Saved Jobs Section */}
              <div className='flex flex-col gap-4 '>
                <h1 className='text-2xl sm:text-xl mb-0'>Saved Jobs</h1>
                <div className='relative bg-gray-300 rounded-md p-4 flex flex-col gap-2' style={{backgroundImage: `url("${randomBanner}")`,backgroundSize: "cover",backgroundRepeat: "no-repeat"}}>
                    <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg"></div>
                    {savedJobs.length!==0?(
                      savedJobs.map((job) => (
                        <SavedJobs key={job.jobID} jobs={job} onRemove={removeSavedJob}/>
                      ))
                    ):(
                        <div className='flex items-center justify-center text-white font-semibold h-40 rounded-md z-1'>No Jobs saved yet!</div>
                    )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Notfound />
        )
      
      ) : (
        <NotLoggedIn />
      )}
      <EditProfileModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} Email={userData.email}/>
    </>
  )
}

export default Profile