import React from 'react'
import { Link } from 'react-router-dom'

const NotLoggedIn = () => {
  return (
    <div className="font-free flex flex-col items-center justify-center h-screen p-10 text-center">
        <h1 className="text-3xl font-bold text-gray-800 mb-2 sm:text-xl">You are not Logged In</h1>
        <p className="text-md text-gray-600 sm:text-[0.7rem]">Login to access this page <Link to='/login'>Login</Link></p>
    </div>
  )
}

export default NotLoggedIn