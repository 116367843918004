import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import Icons from '../../utils/icon';

const ForgotPassModal = ({ isModalOpen,setIsModalOpen}) => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (isModalOpen) document.body.classList.add("overflow-hidden");
    else document.body.classList.remove("overflow-hidden");
  }, [isModalOpen]);


  const handleReset = async (e) => {
    e.preventDefault();
    if(newPassword!==confirmNewPassword){
      toast.error("password and confirm password do not match");
      return;
    }
    try {
      const res= await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/resetPassword`, { email, otp:verificationCode, password:newPassword });
      if(res.data.status){
        toast.success(res.data.message);
        setIsModalOpen(false);
      }
    } catch (e) {
      if(e.response && e.response.data && e.response.data.status === false){
        toast.error(e.response.data.message);
      }
    }
  };

  
  const handleSendCode = async (e) => {
    e.preventDefault();
    try{
       const res= await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/forgetPassword`, { email });
       if(res.data.status){
        toast.success(res.data.message);
        setStep(2);
      }
    }catch(e){
      if(e.response && e.response.data && e.response.data.status === false){
        toast.error(e.response.data.message);
      }
    }
  };

  const handleCross=()=>{
      setStep(1);
      setEmail('');
      setVerificationCode('');
      setNewPassword('');
      setConfirmNewPassword('');
      setIsModalOpen(false);
  }

  return(
    <>
      {isModalOpen && (
        <div className='font-out flex items-center justify-center absolute inset-0 bg-black z-20 bg-opacity-75 px-4'>
          {step===1 && (
            <form className='rounded-md bg-white p-4 flex flex-col w-96 sm:w-full' onSubmit={handleSendCode} >
              <div className='flex items-center justify-between mb-3'>
                <h1 className='text-lg mb-0'>Enter your email</h1>
                <span className='text-xl cursor-pointer'  onClick={handleCross}>{Icons.cross}</span>
              </div>
              <input
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm w-full mb-3"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
              />
              <button type='submit' className="bg-blue-500 rounded-lg text-white py-2 sm:text-sm px-4 hover:bg-blue-600">Send Verification Code</button>
            </form>
          )}
          {step===2 &&(
            <form className='rounded-md bg-white p-4 flex flex-col gap-2 w-96 sm:w-full' onSubmit={handleReset}>
             <div className='flex justify-between items-center mb-3'>
                <h1 className='text-lg mb-0'>Enter New Password</h1>
                <span className='text-xl cursor-pointer'  onClick={handleCross}>{Icons.cross}</span>
              </div>
              <input
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm mb-3"
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Verification Code"
                  required
              />
               <input
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm mb-3 "
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="New Password"
                  required
              />
               <input
                  className="p-2 rounded-lg outline-0 border text-md sm:text-sm mb-3"
                  type="password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  placeholder="Confirm New Password"
                  required
              />
              <button type='submit' className="bg-blue-500 rounded-lg text-white py-2 sm:text-sm px-4 hover:bg-blue-600">Reset Password</button>
            </form>
          )}
          {/* {step===3 &&(
            <form className='rounded-md bg-white p-4' onSubmit={handleResetPassword}>
              <h1 className='text-lg mb-3'>Enter Your New Password</h1>
             
              <button type='submit' className="bg-blue-500 rounded-lg text-white py-2 sm:text-sm px-4 hover:bg-blue-600">Reset Password</button>
            </form>
          )} */}
        </div>
      )}
    </>
  );
};

export default ForgotPassModal;
