import { initializeApp } from "firebase/app";
import {getStorage,ref,uploadBytes,getDownloadURL} from "firebase/storage";
import {v4} from 'uuid';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain:"devjobs-me.firebaseapp.com",
    projectId:"devjobs-me",
    storageBucket:"devjobs-me.appspot.com",
    messagingSenderId: "480911780290",
    appId:process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const imageDb= getStorage(app);

//upload profile image to firebase
export const firebaseImgUpload = async(file)=>{
    try {
        const imgRef = ref(imageDb, `images/${v4()}`);
        await uploadBytes(imgRef, file);
        const url = await getDownloadURL(imgRef);
        return url;
    } catch (error) {
        throw error;
    }
}